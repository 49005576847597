import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, mapTo, Observable, of, shareReplay, switchMap } from 'rxjs';
import { filterNull } from '@protctc/common/core/rxjs/filter-null';
import { UserService } from '@protctc/common/core/services/user.service';

import { MerchantPartnerSortField } from '../enums/merchant-partner-sort-field';
import { Company } from '../models/company/company';
import { CompanyLogo } from '../models/company-logo';
import { CustomKeyValue } from '../models/custom-key-value';
import { MerchantPartner } from '../models/company/merchant-partner';
import { MerchantPartnerPaginationOptions } from '../models/merchant-partner-pagination-options';
import { PaymentPublicKey } from '../models/payment-public-key';
import { Pagination } from '../models/pagination';
import { State } from '../models/state';
import { VerifyEmailExistenceData } from '../models/verify-email-existence-data';
import { CompanyStatistics } from '../models/company-statistics';
import { CompanyStatisticsOptions } from '../models/company-statistics-options';
import { CompanyFieldUserPaginationOptions } from '../models/company-field-user-pagination-options';
import { CompanyFieldUser } from '../models/company-field-user';
import { CompanyFieldUserSortField } from '../enums/company-field-user-sort-field';
import { DashboardChartData } from '../models/dashboard-chart-data';
import { DashboardChartDataOptions } from '../models/dashboard-chart-data-options';
import { AppError } from '../models/app-error';

import { AppConfigService } from './app-config.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { CompanyLogoMapper } from './mappers/company-logo.mapper';
import { CompanyMapper } from './mappers/company/company.mapper';
import { CustomKeyValueMapper } from './mappers/custom-key-value.mapper';
import { CompanyDto } from './mappers/dto/company/company.dto';
import { CompanyLogoDto } from './mappers/dto/company-logo-dto';
import { CustomKeyValueDto } from './mappers/dto/custom-key-value-dto';
import { MerchantPartnerDto } from './mappers/dto/merchant-partner-dto';
import { MerchantPartnerPaginationOptionsDto } from './mappers/dto/merchant-partner-pagination-options-dto';
import { PaymentPublicKeyDto } from './mappers/dto/payment-public-key-dto';
import { PaginationDto } from './mappers/dto/pagination-dto';
import { StateDto } from './mappers/dto/state-dto';
import { MerchantPartnerMapper } from './mappers/merchant-partner.mapper';
import { PaymentPublicKeyMapper } from './mappers/payment-public-key.mapper';
import { PaginationMapper } from './mappers/pagination.mapper';
import { SortMapper } from './mappers/sort.mapper';
import { StateMapper } from './mappers/state.mapper';
import { VerifyEmailExistenceMapper } from './mappers/verify-email-existence.mapper';
import { CompanyStatisticsOptionsDto } from './mappers/dto/company-statistics-options-dto';
import { CompanyStatisticsDto } from './mappers/dto/company-statistics-dto';
import { CompanyStatisticsMapper } from './mappers/company-statistics.mapper';
import { CompanyFieldUserPaginationOptionsDto } from './mappers/dto/company-field-user-pagination-options-dto';
import { CompanyFieldUserDto } from './mappers/dto/company-field-user-dto';
import { CompanyFieldUserMapper } from './mappers/company-field-user.mapper';
import { DashboardChartDataDto } from './mappers/dto/dashboard-chart-data-dto';
import { DashboardChartDataMapper } from './mappers/dashboard-chart-data.mapper';
import { DashboardChartDataOptionsDto } from './mappers/dto/dashboard-chart-data-options-dto';
import { DateMapper } from './mappers/date.mapper';

/** Mapper to map merchant partner sort field from domain to local. */
const MERCHANT_PARTNER_SORT_FIELD_MAP: Readonly<Record<MerchantPartnerSortField, string>> = {
  [MerchantPartnerSortField.Id]: 'id',
  [MerchantPartnerSortField.Name]: 'name',
  [MerchantPartnerSortField.EIN]: 'ein',
  [MerchantPartnerSortField.Email]: 'email',
  [MerchantPartnerSortField.InvoiceCount]: 'invoice_count',
  [MerchantPartnerSortField.LastActivity]: 'last_activity',
  [MerchantPartnerSortField.DeactivatedAt]: 'deactivated_at',
};

/** Mapper to map company field users sort field from domain to local. */
const COMPANY_FIELD_USERS_SORT_FIELD_MAP: Readonly<Record<CompanyFieldUserSortField, string>> = {
  [CompanyFieldUserSortField.Name]: 'first_name,last_name',
  [CompanyFieldUserSortField.InvoiceCount]: 'invoice_count',
  [CompanyFieldUserSortField.TotalAmount]: 'total_amount',
  [CompanyFieldUserSortField.LastActivity]: 'last_login',
};

/**
 * Company service.
 * Provides ability to work with company entities.
 */
@Injectable({
  providedIn: 'root',
})
export class CompanyService {

  /** Companies url. */
  public readonly companiesUrl: string;

  private readonly companyUrl: string;

  private readonly companyAdminUrl: string;

  /**
   * Current company logo.
   */
  public readonly currentCompanyLogo$ = this.userService.currentUser$.pipe(
    filterNull(),
    switchMap(user => user.companyId ? this.getCompanyLogo(user.companyId) : of(null)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  public constructor(
    appConfig: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly userService: UserService,
    private readonly customKeyValueMapper: CustomKeyValueMapper,
    private readonly stateMapper: StateMapper,
    private readonly companyMapper: CompanyMapper,
    private readonly paginationMapper: PaginationMapper,
    private readonly sortMapper: SortMapper,
    private readonly companyLogoMapper: CompanyLogoMapper,
    private readonly merchantPartnerMapper: MerchantPartnerMapper,
    private readonly paymentPublicKeyMapper: PaymentPublicKeyMapper,
    private readonly verifyEmailExistenceMapper: VerifyEmailExistenceMapper,
    private readonly companyStatisticsMapper: CompanyStatisticsMapper,
    private readonly companyFieldUserMapper: CompanyFieldUserMapper,
    private readonly dashboardChartDataMapper: DashboardChartDataMapper,
    private readonly dateMapper: DateMapper,
  ) {
    this.companiesUrl = new URL('companies/', appConfig.apiUrl).toString();
    this.companyUrl = new URL('company/', this.companiesUrl).toString();
    this.companyAdminUrl = new URL('admin/company/', this.companiesUrl).toString();
  }

  /**
   * Get company fields users.
   * @param options Pagination options.
   */
  public getCompanyFieldUsers(
    options: CompanyFieldUserPaginationOptions,
  ): Observable<Pagination<CompanyFieldUser>> {
    const optionsDto: CompanyFieldUserPaginationOptionsDto = {
      ...this.paginationMapper.mapOptionsToDto(options),
      ordering: this.sortMapper.mapSortOptionsToDto(options.sortOptions, COMPANY_FIELD_USERS_SORT_FIELD_MAP),
      ...this.mapDateRangeToDto(options.companyFieldUserFilterData),
    };

    const params = new HttpParams({
      fromObject: { ...optionsDto },
    });

    const dashboardFieldUsersUrl = new URL('dashboard/field_users/', this.companiesUrl).toString();
    return this.httpClient.get<PaginationDto<CompanyFieldUserDto>>(
      dashboardFieldUsersUrl,
      { params },
    ).pipe(
      map(page => this.paginationMapper.mapPaginationFromDto(page, options, this.companyFieldUserMapper)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get company dashboard chart data.
   * @param options Dashboard chart data options.
   */
  public getCompanyDashboardChartData(options: DashboardChartDataOptions): Observable<DashboardChartData[]> {
    const optionsDto: DashboardChartDataOptionsDto = {
      ...this.mapDateRangeToDto(options),
      group_by: options.groupBy,
    };

    const params = new HttpParams({
      fromObject: { ...optionsDto },
    });

    const dashboardChartDataUrl = new URL('dashboard/chart_data/', this.companiesUrl).toString();
    return this.httpClient.get<DashboardChartDataDto[]>(
      dashboardChartDataUrl,
      { params },
    ).pipe(
      map(dashboardChartDataDto => dashboardChartDataDto.map(dto => this.dashboardChartDataMapper.fromDto(dto))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get company statistics.
   * @param options Company statistics options.
   */
  public getCompanyStatistics(options: CompanyStatisticsOptions): Observable<CompanyStatistics> {
    const optionsDto: CompanyStatisticsOptionsDto = {
      ...this.mapDateRangeToDto(options),
    };

    const params = new HttpParams({
      fromObject: { ...optionsDto },
    });

    const url = new URL('dashboard/statistics/', this.companiesUrl).toString();
    return this.httpClient.get<CompanyStatisticsDto>(
      url,
      { params },
    ).pipe(
      map(statisticsDto => this.companyStatisticsMapper.fromDto(statisticsDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Verify email existence.
   * @param verifyEmailExistenceData Verify email existence data.
   */
  public verifyEmailExistence(verifyEmailExistenceData: VerifyEmailExistenceData): Observable<void> {
    const verifyEmailExistenceUrl = new URL('email/user/verify/', this.companiesUrl).toString();
    return this.httpClient.post<void>(
      verifyEmailExistenceUrl,
      this.verifyEmailExistenceMapper.toDto(verifyEmailExistenceData),
    ).pipe(
      mapTo(void 0),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.verifyEmailExistenceMapper),
    );
  }

  /**
   * Get company information by item id.
   * @param id Company id.
   * @param forAdmin Get a company for the administrator or Merchant.
   */
  public getCompanyById(id: number, forAdmin = true): Observable<Company> {
    const url = new URL(`${id}/`, forAdmin ? this.companyAdminUrl : this.companyUrl).toString();
    return this.httpClient.get<CompanyDto>(
      url,
    ).pipe(
      map(companyDto => this.companyMapper.fromDto(companyDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /** Get available company types. */
  public getCompanyTypes(): Observable<CustomKeyValue<string, string>[]> {
    const url = new URL('company_types/', this.companyUrl);
    return this.httpClient.get<CustomKeyValueDto<string, string>[]>(url.toString()).pipe(
      map(types => types.map(type => this.customKeyValueMapper.fromDto(type))),
    );
  }

  /** Get refund policy options. */
  public getRefundPolicyOptions(): Observable<CustomKeyValue<string, string>[]> {
    const url = new URL('refund_policy_options/', this.companyUrl).toString();
    return this.httpClient.get<CustomKeyValue<string, string>[]>(url).pipe(
      map(optionsDto => optionsDto.map(option => this.customKeyValueMapper.fromDto(option))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /** Get states. */
  public getStates(): Observable<State[]> {
    const url = new URL('states/', this.companiesUrl).toString();
    return this.httpClient.get<StateDto[]>(url).pipe(
      map(stateDto => stateDto.map(state => this.stateMapper.fromDto(state))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get company logo by id.
   * @param id Company id.
   */
  public getCompanyLogo(id: number): Observable<CompanyLogo> {
    const url = new URL(`${id}/logo/`, this.companyUrl).toString();
    return this.httpClient.get<CompanyLogoDto>(url).pipe(
      map(logo => this.companyLogoMapper.fromDto(logo)),
    );
  }

  /**
   * Deactivate merchant partner.
   * @param id Merchant partner id.
   */
  public deactivateMerchantPartner(id: MerchantPartner['id']): Observable<void> {
    const url = new URL(`${id}/deactivate/`, this.companyAdminUrl).toString();
    return this.httpClient.post<void>(url, {}).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Activate merchant partner.
   * @param id Merchant partner id.
   */
  public activateMerchantPartner(id: MerchantPartner['id']): Observable<void> {
    const url = new URL(`${id}/activate/`, this.companyAdminUrl).toString();
    return this.httpClient.post<void>(url, {}).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get merchant partners.
   * @param options Pagination options.
   */
  public getMerchantPartners(options: MerchantPartnerPaginationOptions): Observable<Pagination<MerchantPartner>> {
    const pagination: MerchantPartnerPaginationOptionsDto = {
      ...this.paginationMapper.mapOptionsToDto(options),
      ordering: this.sortMapper.mapSortOptionsToDto(options.sortOptions, MERCHANT_PARTNER_SORT_FIELD_MAP),
      status: options.merchantPartnerStatus,
      search: options.searchString,
      ...this.mapDateRangeToDto(options.merchantPartnerFilterData),
    };

    const params = new HttpParams({
      fromObject: { ...pagination },
    });

    const url = new URL('admin/merchant_partner/', this.companiesUrl).toString();
    return this.httpClient.get<PaginationDto<MerchantPartnerDto>>(
      url,
      { params },
    ).pipe(
      map(page => this.paginationMapper.mapPaginationFromDto(page, options, this.merchantPartnerMapper)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get payment token for the company.
   * @param companyId Company id.
   */
  public getPaymentToken(companyId: number): Observable<PaymentPublicKey> {
    const url = new URL(`${companyId}/payrix_key/`, this.companyUrl).toString();
    return this.httpClient.get<PaymentPublicKeyDto>(url).pipe(
      map(paymentPublicKeyDto => this.paymentPublicKeyMapper.fromDto(paymentPublicKeyDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Map date range company statistics options to dto.
   * @param options Company statistics options.
   */
  public mapDateRangeToDto<T extends CompanyStatisticsOptions>(
    options: T,
  ): Record<keyof CompanyStatisticsOptionsDto, string> {
    return {
      date_after: options.dateAfter ?
        this.dateMapper.toUTCDateStringDto(options.dateAfter) : '',
      date_before: options.dateBefore ?
        this.dateMapper.toUTCDateStringDto(options.dateBefore) : '',
    };
  }

  /**
   * Activate monthly subscription.
   * @param merchantId Merchant id.
   */
  public activateMonthlySubscription(merchantId: number): Observable<void> {
    const url = new URL(`${merchantId.toString()}/activate_subscription/`, this.companyUrl).toString();
    return this.httpClient.put<void>(url, {}).pipe(
      catchError(error => {
        throw new AppError(error.error.detail);
      }),
    );
  }

  /**
   * Deactivate monthly subscription.
   * @param merchantId Merchant id.
   */
  public deactivateMonthlySubscription(merchantId: number): Observable<void> {
    const url = new URL(`${merchantId.toString()}/deactivate_subscription/`, this.companyUrl).toString();
    return this.httpClient.put<void>(url, {}).pipe(
      catchError(error => {
        throw new AppError(error.error.detail);
      }),
    );
  }
}
